* {
    font-family: 'adobe-clean', Times New Roman, serif !important;
}

.centered {
    position: fixed; /* or absolute */
    top: 50%;
    left: 50%;
}

button:hover,
a:hover {
    cursor: pointer;
}


::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #B1B1B1;
}

::-webkit-scrollbar-track {
    background: #EAEAEA;
}

::-webkit-scrollbar-thumb:hover {
    background: #A1A1A1;
}

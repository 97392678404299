.error-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    height: 100vh;
}

.error-wrapper svg {
    width: 280px;
    height: 280px;
    fill: #b3b3b3;
}

.error-title {
    font-size: 28px;
    color: #323232;
    font-weight: bold;
}

.error-desc {
    font-size: 18px;
    color: #505050;
}

.error-content-wrapper {
    margin-top: 32px;
}
